import { Roles } from '@/domain/accounts/roles';
import { type RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { withGuard } from '../utils/with-guard/with-guard';
import { withLazy } from '../utils/with-lazy';

const { OnboardingLayout } = withLazy(() => import('../layouts/onboarding-layout'), 'OnboardingLayout');

const { FounderOnboardingStepPage } = withLazy(
  () => import('@/pages/content/onboarding/founder/pages/onboarding-step.page'),
  'FounderOnboardingStepPage',
);

const { InvestorOnboardingWelcomePage } = withLazy(
  () => import('@/pages/content/onboarding/investor/pages/investor-onboarding-welcome.page'),
  'InvestorOnboardingWelcomePage',
);

const { InvestorOnboardingStepPage } = withLazy(
  () => import('@/pages/content/onboarding/investor/pages/investor-onboarding-step.page'),
  'InvestorOnboardingStepPage',
);

const { InvestorOnboardingSummaryPage } = withLazy(
  () => import('@/pages/content/onboarding/investor/pages/investor-onboarding-summary.page'),
  'InvestorOnboardingSummaryPage',
);

/**********************************************************
 * GUARDED COMPONENTS
 */
const GuardedOnboardingLayout = withGuard(OnboardingLayout, {
  roles: [Roles.FOUNDER, Roles.INVESTOR, Roles.NED],
});

const GuardedFounderOnboardingStepPage = withGuard(FounderOnboardingStepPage, {
  roles: [Roles.FOUNDER],
  isActive: true,
  hasCor: true,
});

const GuardedInvestorOnboardingWelcomePage = withGuard(InvestorOnboardingWelcomePage, {
  roles: [Roles.INVESTOR, Roles.NED],
  isActive: (r) => {
    if (r === Roles.INVESTOR) return false;
    return true;
  },
  isOnboard: false,
  hasCor: true,
});

const GuardedInvestorOnboardingStepPage = withGuard(InvestorOnboardingStepPage, {
  roles: [Roles.INVESTOR, Roles.NED],
  isActive: (r) => {
    if (r === Roles.INVESTOR) return false;
    return true;
  },
  isOnboard: false,
  hasCor: true,
});

const GuardedInvestorOnboardingSummaryPage = withGuard(InvestorOnboardingSummaryPage, {
  roles: [Roles.INVESTOR, Roles.NED],
  isActive: (r) => {
    if (r === Roles.INVESTOR) return false;
    return true;
  },
  isOnboard: true,
  hasCor: true,
});

export const onboardingRoutes: RouteObject[] = [
  {
    path: V2Routes.ONBOARDING_ROOT,
    element: <GuardedOnboardingLayout />,
    children: [
      /**********************************************************
       *  FOUNDER ONBOARDING ROUTES
       */
      { path: V2Routes.ONBOARDING_FOUNDER_STEP, element: <GuardedFounderOnboardingStepPage /> },

      /**********************************************************
       *  INVESTOR ONBOARDING ROUTES
       */
      { path: V2Routes.ONBOARDING_INVESTOR, element: <GuardedInvestorOnboardingWelcomePage /> },
      { path: V2Routes.ONBOARDING_INVESTOR_SUMMARY, element: <GuardedInvestorOnboardingSummaryPage /> },
      { path: V2Routes.ONBOARDING_INVESTOR_STEP, element: <GuardedInvestorOnboardingStepPage /> },
    ],
  },
];
