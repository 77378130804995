import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid
    ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[200], theme.v2.colors.neutral[600])};
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[600])};
  box-shadow: ${({ theme }) => theme.v2.shadows.button};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    height: 42px;
    width: 42px;
  }

  &:disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }
`;

const ButtonPrev = styled(Button)`
  position: absolute;
  top: -25px;
  left: 50px;
  transform: translate(-125%, -50%);

  ${customMediaQuery('top', {
    sm: '35%',
  })}
  ${customMediaQuery('left', {
    sm: '0',
  })}
`;

const ButtonNext = styled(Button)`
  position: absolute;
  top: -25px;
  right: 50px;
  transform: translate(125%, -50%);

  ${customMediaQuery('top', {
    sm: '35%',
  })}
  ${customMediaQuery('right', {
    sm: '0',
  })}
`;

const ContentWrapper = styled.div`
  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;

export default {
  ButtonPrev,
  ButtonNext,
  ContentWrapper,
};
