import { Roles } from '@/domain/accounts/roles';
import type { RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { withGuard } from '../utils/with-guard/with-guard';
import { withLazy } from '../utils/with-lazy';

/**********************************************************
 *  LAZY LOADING COMPONENTS
 */
const { InvestorAdvisorLayout } = withLazy(() => import('../layouts/investor-advisor-layout'), 'InvestorAdvisorLayout');

const { Homepage } = withLazy(() => import('@/modules/investor-advisor/homepage/homepage'), 'Homepage');

const { InvestorNedProfilePageWithStateProvider: InvestorNedProfilePage } = withLazy(
  () => import('@/pages/content/profile/investor-ned/investor-ned-profile.page'),
  'InvestorNedProfilePageWithStateProvider',
);

const { InvestorAdvisorConnectionsPage } = withLazy(
  () => import('@/modules/investor-advisor/connections/connections-page'),
  'InvestorAdvisorConnectionsPage',
);

const { Chat: InvestorNedMessaging } = withLazy(() => import('@/pages/content/messaging/parts/chat/chat'), 'Chat');

const { InvestorNedNotifications } = withLazy(
  () => import('@/pages/content/notifications/investor-ned/investor-ned-notifications.page'),
  'InvestorNedNotifications',
);

const { InvestorPortfolio } = withLazy(
  () => import('@/pages/content/portfolio/investor/investor-portfolio.page'),
  'InvestorPortfolio',
);

const { ExpandView } = withLazy(
  () => import('@/pages/content/portfolio/investor/expand-view/expand-view.page'),
  'ExpandView',
);

const { CompanyDetailsPage } = withLazy(
  () => import('@/pages/content/company-details/company-details.page'),
  'CompanyDetailsPage',
);

const { InvestorPulsePage } = withLazy(
  () => import('@/pages/content/pulse/investor/investor-pulse.page'),
  'InvestorPulsePage',
);

const { FounderPublicPage } = withLazy(
  () => import('@/pages/content/public-profile/founder/founder.page'),
  'FounderPublicPage',
);

const { NedAcademyAccessPage } = withLazy(
  () => import('@/pages/utility/ned-academy-access/ned-academy-access.page'),
  'NedAcademyAccessPage',
);

const { Legal } = withLazy(() => import('@/pages/content/legal/legal.page'), 'Legal');

const { NedCatalogue } = withLazy(() => import('@/pages/content/roles/catalogue/ned/ned.page'), 'NedCatalogue');

const { NedPreview } = withLazy(() => import('@/pages/content/roles/preview/ned/ned.page'), 'NedPreview');

const { InvestmentConfirmationPage } = withLazy(
  () => import('@/pages/content/investment-confirmation/investment-confirmation.page'),
  'InvestmentConfirmationPage',
);

const { Startups } = withLazy(() => import('@/modules/advisor/startups/startups'), 'Startups');

/**********************************************************
 * GUARDED COMPONENTS
 */
const GuardedInvestorAdvisorLayout = withGuard(InvestorAdvisorLayout, {
  roles: [Roles.INVESTOR_NED],
  isActive: true,
  hasCor: true,
});

const GuardedPortfolioPage = withGuard(InvestorPortfolio, {
  apiPermissions: true,
});

export const investorAdvisorRoutes: RouteObject[] = [
  {
    path: V2Routes.INVESTOR_ADVISOR_ROOT,
    element: <GuardedInvestorAdvisorLayout />,
    children: [
      { path: V2Routes.INVESTOR_ADVISOR_HOMEPAGE, element: <Homepage /> },
      { path: V2Routes.INVESTOR_ADVISOR_PROFILE, element: <InvestorNedProfilePage /> },
      { path: V2Routes.INVESTOR_ADVISOR_MESSAGES, element: <InvestorNedMessaging /> },
      { path: V2Routes.INVESTOR_ADVISOR_NOTIFICATIONS, element: <InvestorNedNotifications /> },
      { path: V2Routes.INVESTOR_ADVISOR_CONNECTIONS, element: <InvestorAdvisorConnectionsPage /> },
      { path: V2Routes.INVESTOR_ADVISOR_PORTFOLIO, element: <GuardedPortfolioPage /> },
      { path: V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW, element: <ExpandView /> },
      { path: V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW_SINGLE, element: <ExpandView /> },
      { path: V2Routes.INVESTOR_ADVISOR_PORTFOLIO_COMPANY_DETAILS, element: <CompanyDetailsPage /> },
      { path: V2Routes.INVESTOR_ADVISOR_PULSE, element: <InvestorPulsePage /> },
      { path: V2Routes.INVESTOR_ADVISOR_LENS, element: <Startups /> },
      { path: V2Routes.INVESTOR_ADVISOR_PUBLIC_PROFILE_FOUNDER, element: <FounderPublicPage /> },
      { path: V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES, element: <NedCatalogue /> },
      { path: V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES_ROLE_PREVIEW, element: <NedPreview /> },
      { path: V2Routes.INVESTOR_ADVISOR_LEGAL, element: <Legal /> },
      { path: V2Routes.INVESTOR_ADVISOR_CONFIRM_INVESTMENT, element: <InvestmentConfirmationPage /> },
      { path: V2Routes.INVESTOR_ADVISOR_ACADEMY, element: <NedAcademyAccessPage /> },
    ],
  },
];
