import { Roles } from '@/domain/accounts/roles';
import { LegacyRoutes, V2Routes } from './routes';

export const redirectFromLegacy: Record<LegacyRoutes, Record<Roles, V2Routes>> = {
  [LegacyRoutes.ROOT]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ROOT,
    [Roles.INVESTOR]: V2Routes.INVESTOR_ROOT,
    [Roles.NED]: V2Routes.ADVISOR_ROOT,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_ROOT,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.LEGACY_START_PAGE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ROOT,
    [Roles.INVESTOR]: V2Routes.INVESTOR_ROOT,
    [Roles.NED]: V2Routes.ADVISOR_ROOT,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_ROOT,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },

  // Authentication Routes
  [LegacyRoutes.LOGIN]: {
    [Roles.FOUNDER]: V2Routes.SHARED_LOGIN,
    [Roles.INVESTOR]: V2Routes.SHARED_LOGIN,
    [Roles.NED]: V2Routes.SHARED_LOGIN,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_LOGIN,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.REGISTER]: {
    [Roles.FOUNDER]: V2Routes.SHARED_REGISTER,
    [Roles.INVESTOR]: V2Routes.SHARED_REGISTER,
    [Roles.NED]: V2Routes.SHARED_REGISTER,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_REGISTER,
    [Roles.NO_ROLE]: V2Routes.SHARED_REGISTER,
  },
  [LegacyRoutes.REDIRECT_TO_REGISTER]: {
    [Roles.FOUNDER]: V2Routes.SHARED_REDIRECT_TO_REGISTER,
    [Roles.INVESTOR]: V2Routes.SHARED_REDIRECT_TO_REGISTER,
    [Roles.NED]: V2Routes.SHARED_REDIRECT_TO_REGISTER,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_REDIRECT_TO_REGISTER,
    [Roles.NO_ROLE]: V2Routes.SHARED_REDIRECT_TO_REGISTER,
  },
  [LegacyRoutes.REGISTER_ROLE]: {
    [Roles.FOUNDER]: V2Routes.SHARED_REGISTER_ROLE,
    [Roles.INVESTOR]: V2Routes.SHARED_REGISTER_ROLE,
    [Roles.NED]: V2Routes.SHARED_REGISTER_ROLE,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_REGISTER_ROLE,
    [Roles.NO_ROLE]: V2Routes.SHARED_REGISTER_ROLE,
  },

  // Checkout Routes
  [LegacyRoutes.CHECKOUT]: {
    [Roles.FOUNDER]: V2Routes.SHARED_CHECKOUT,
    [Roles.INVESTOR]: V2Routes.SHARED_CHECKOUT,
    [Roles.NED]: V2Routes.SHARED_CHECKOUT,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_CHECKOUT,
    [Roles.NO_ROLE]: V2Routes.SHARED_CHECKOUT,
  },
  [LegacyRoutes.CHECKOUT_SUCCESS]: {
    [Roles.FOUNDER]: V2Routes.SHARED_CHECKOUT_SUCCESS,
    [Roles.INVESTOR]: V2Routes.SHARED_CHECKOUT_SUCCESS,
    [Roles.NED]: V2Routes.SHARED_CHECKOUT_SUCCESS,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_CHECKOUT_SUCCESS,
    [Roles.NO_ROLE]: V2Routes.SHARED_CHECKOUT_SUCCESS,
  },
  [LegacyRoutes.CHECKOUT_CANCEL]: {
    [Roles.FOUNDER]: V2Routes.SHARED_CHECKOUT_CANCEL,
    [Roles.INVESTOR]: V2Routes.SHARED_CHECKOUT_CANCEL,
    [Roles.NED]: V2Routes.SHARED_CHECKOUT_CANCEL,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_CHECKOUT_CANCEL,
    [Roles.NO_ROLE]: V2Routes.SHARED_CHECKOUT_CANCEL,
  },
  [LegacyRoutes.PAYMENT_IN_PROGRESS]: {
    [Roles.FOUNDER]: V2Routes.SHARED_PAYMENT_IN_PROGRESS,
    [Roles.INVESTOR]: V2Routes.SHARED_PAYMENT_IN_PROGRESS,
    [Roles.NED]: V2Routes.SHARED_PAYMENT_IN_PROGRESS,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_PAYMENT_IN_PROGRESS,
    [Roles.NO_ROLE]: V2Routes.SHARED_PAYMENT_IN_PROGRESS,
  },

  // Error Routes
  [LegacyRoutes.CATCH_ALL]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ERROR_404_PAGE]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.ERROR_404,
  },
  [LegacyRoutes.ERROR_403_PAGE]: {
    [Roles.FOUNDER]: V2Routes.ERROR_403,
    [Roles.INVESTOR]: V2Routes.ERROR_403,
    [Roles.NED]: V2Routes.ERROR_403,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_403,
    [Roles.NO_ROLE]: V2Routes.ERROR_403,
  },
  [LegacyRoutes.INTERNAL_SERVER_ERROR]: {
    [Roles.FOUNDER]: V2Routes.ERROR_500,
    [Roles.INVESTOR]: V2Routes.ERROR_500,
    [Roles.NED]: V2Routes.ERROR_500,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_500,
    [Roles.NO_ROLE]: V2Routes.ERROR_500,
  },

  // Profile Routes
  [LegacyRoutes.PROFILE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_PROFILE,
    [Roles.INVESTOR]: V2Routes.INVESTOR_PROFILE,
    [Roles.NED]: V2Routes.ADVISOR_PROFILE,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PROFILE,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.MULTI_ROLE_PROFILE]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PROFILE,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },

  // Onboarding Routes
  [LegacyRoutes.ONBOARDING_FOUNDER_STEP]: {
    [Roles.FOUNDER]: V2Routes.ONBOARDING_FOUNDER_STEP,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ONBOARDING_INVESTOR]: {
    [Roles.FOUNDER]: V2Routes.ONBOARDING_ROOT,
    [Roles.INVESTOR]: V2Routes.ONBOARDING_INVESTOR,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ONBOARDING_INVESTOR,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ONBOARDING_INVESTOR_SUMMARY]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.ONBOARDING_INVESTOR_SUMMARY,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ONBOARDING_INVESTOR_SUMMARY,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },

  // App Routes
  [LegacyRoutes.LENS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_INVESTORS,
    [Roles.INVESTOR]: V2Routes.INVESTOR_LENS,
    [Roles.NED]: V2Routes.ADVISOR_LENS,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_LENS,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ADVISORS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ADVISORS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.CONNECTIONS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_CONNECTIONS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_CONNECTIONS,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_CONNECTIONS,
    [Roles.NO_ROLE]: V2Routes.ERROR_404,
  },
  [LegacyRoutes.ADVISORS_SEARCH_ROLE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ADVISORS_SEARCH_ROLE,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.INVESTOR_PUBLIC_PROFILE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_PUBLIC_PROFILE_INVESTOR,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.FOUNDER_PUBLIC_PROFILE]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.INVESTOR_PUBLIC_PROFILE_FOUNDER,
    [Roles.NED]: V2Routes.ADVISOR_PUBLIC_PROFILE_FOUNDER,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PUBLIC_PROFILE_FOUNDER,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.PULSE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_REPORT,
    [Roles.INVESTOR]: V2Routes.INVESTOR_PULSE,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PULSE,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.MANAGE_METRIC]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_METRICS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.NOTIFICATIONS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_NOTIFICATIONS,
    [Roles.INVESTOR]: V2Routes.INVESTOR_NOTIFICATIONS,
    [Roles.NED]: V2Routes.ADVISOR_NOTIFICATIONS,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_NOTIFICATIONS,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.LEGAL]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_LEGAL,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_LEGAL,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_LEGAL,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.MESSAGING]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_MESSAGES,
    [Roles.INVESTOR]: V2Routes.INVESTOR_MESSAGES,
    [Roles.NED]: V2Routes.ADVISOR_MESSAGES,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_MESSAGES,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.NED_PUBLIC_PROFILE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_PUBLIC_PROFILE_ADVISOR,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ROLES]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ROLES,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_OPPORTUNITIES,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ROLE_APPLICATIONS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ROLES_APPLICATIONS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_OPPORTUNITIES,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.CREATE_NEW_ROLE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ROLES_CREATE,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_OPPORTUNITIES,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.PREVIEW_ROLE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ROLES_PREVIEW,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_OPPORTUNITIES_ROLE_PREVIEW,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES_ROLE_PREVIEW,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.EDIT_ROLE]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_ROLES_EDIT,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },

  // Miscellaneous Routes
  [LegacyRoutes.COR_SELECTION]: {
    [Roles.FOUNDER]: V2Routes.SHARED_COR_SELECTION,
    [Roles.INVESTOR]: V2Routes.SHARED_COR_SELECTION,
    [Roles.NED]: V2Routes.SHARED_COR_SELECTION,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_COR_SELECTION,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ADVISOR_CONNECTIONS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_CONNECTIONS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_CONNECTIONS,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_CONNECTIONS,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.INVESTOR_ADVISOR_CONNECTIONS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_CONNECTIONS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_CONNECTIONS,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_CONNECTIONS,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.FOUNDER_CONNECTIONS]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_CONNECTIONS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_CONNECTIONS,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_CONNECTIONS,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.FOUNDER_CONFIRM_INVESTMENT]: {
    [Roles.FOUNDER]: V2Routes.FOUNDER_CONFIRM_INVESTMENT,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.REGISTER_INVITED_FOUNDER]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_REGISTER_INVITED_FOUNDER,
  },
  [LegacyRoutes.REGISTER_INVITED_INVESTOR]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_REGISTER_INVITED_INVESTOR,
  },
  [LegacyRoutes.INVESTOR_CONFIRM_INVESTMENT]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.INVESTOR_CONFIRM_INVESTMENT,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.EXTEND_PRODUCTS]: {
    [Roles.FOUNDER]: V2Routes.SHARED_EXTEND_PRODUCTS,
    [Roles.INVESTOR]: V2Routes.SHARED_EXTEND_PRODUCTS,
    [Roles.NED]: V2Routes.SHARED_EXTEND_PRODUCTS,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_EXTEND_PRODUCTS,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.UPGRADE_PRODUCTS]: {
    [Roles.FOUNDER]: V2Routes.SHARED_UPGRADE_PRODUCTS,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.ERROR_404,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.EMAIL_UNVERIFIED]: {
    [Roles.FOUNDER]: V2Routes.SHARED_EMAIL_UNVERIFIED,
    [Roles.INVESTOR]: V2Routes.SHARED_EMAIL_UNVERIFIED,
    [Roles.NED]: V2Routes.SHARED_EMAIL_UNVERIFIED,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_EMAIL_UNVERIFIED,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.INVESTOR_AWAITING_APPROVAL]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.SHARED_AWAITING_APPROVAL,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_AWAITING_APPROVAL,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.ACCESS_NED_ACADEMY]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.ERROR_404,
    [Roles.NED]: V2Routes.ADVISOR_ACADEMY,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_ACADEMY,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.PORTFOLIO]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.INVESTOR_PORTFOLIO,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PORTFOLIO,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.PORTFOLIO_EXPANDED_VIEW]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.PORTFOLIO_EXPANDED_VIEW_SINGLE]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW_SINGLE,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW_SINGLE,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.PORTFOLIO_COMPANY_DETAILS]: {
    [Roles.FOUNDER]: V2Routes.ERROR_404,
    [Roles.INVESTOR]: V2Routes.INVESTOR_PORTFOLIO_COMPANY_DETAILS,
    [Roles.NED]: V2Routes.ERROR_404,
    [Roles.INVESTOR_NED]: V2Routes.INVESTOR_ADVISOR_PORTFOLIO_COMPANY_DETAILS,
    [Roles.NO_ROLE]: V2Routes.SHARED_LOGIN,
  },
  [LegacyRoutes.PROGRESS_REPORT]: {
    [Roles.FOUNDER]: V2Routes.SHARED_DASHBOARD_REPORT,
    [Roles.INVESTOR]: V2Routes.SHARED_DASHBOARD_REPORT,
    [Roles.NED]: V2Routes.SHARED_DASHBOARD_REPORT,
    [Roles.INVESTOR_NED]: V2Routes.SHARED_DASHBOARD_REPORT,
    [Roles.NO_ROLE]: V2Routes.SHARED_DASHBOARD_REPORT,
  },
};
