import { V2Routes } from './routes';
import type { V2LinksCollection } from './types';

const assignParams = (route: string, params: { [key: string]: string }) => {
  let newRoute = route;
  Object.keys(params).forEach((key) => (newRoute = newRoute.replace(`:${key}`, params[key])));

  return newRoute;
};

export const V2Links: V2LinksCollection = {
  root: () => V2Routes.ROOT,

  /**********************************************************
   *  SHARED ROUTES
   */
  sharedStart: () => V2Routes.SHARED_START,

  sharedLogin: () => V2Routes.SHARED_LOGIN,
  sharedRegister: () => V2Routes.SHARED_REGISTER,
  sharedRegisterRole: (params) => assignParams(V2Routes.SHARED_REGISTER_ROLE, params),
  sharedRedirectToRegister: () => V2Routes.SHARED_REDIRECT_TO_REGISTER,
  sharedEmailUnverified: () => V2Routes.SHARED_EMAIL_UNVERIFIED,

  sharedCheckout: () => V2Routes.SHARED_CHECKOUT,
  sharedCheckoutSuccess: () => V2Routes.SHARED_CHECKOUT_SUCCESS,
  sharedCheckoutCancel: () => V2Routes.SHARED_CHECKOUT_CANCEL,
  sharedPaymentInProgress: () => V2Routes.SHARED_PAYMENT_IN_PROGRESS,
  sharedExtendProducts: () => V2Routes.SHARED_EXTEND_PRODUCTS,
  sharedUpgradeProducts: () => V2Routes.SHARED_UPGRADE_PRODUCTS,

  sharedCorSelection: () => V2Routes.SHARED_COR_SELECTION,
  sharedAwaitingApproval: () => V2Routes.SHARED_AWAITING_APPROVAL,
  sharedDashboardReport: (params) => assignParams(V2Routes.SHARED_DASHBOARD_REPORT, params),

  /**********************************************************
   *  ONBOARDING ROUTES
   */
  onboardingRoot: () => V2Routes.ONBOARDING_ROOT,
  onboardingFounderStep: (params) => assignParams(V2Routes.ONBOARDING_FOUNDER_STEP, params),
  onboardingInvestor: () => V2Routes.ONBOARDING_INVESTOR,
  onboardingInvestorStep: (params) => assignParams(V2Routes.ONBOARDING_INVESTOR_STEP, params),
  onboardingInvestorSummary: () => V2Routes.ONBOARDING_INVESTOR_SUMMARY,

  /**********************************************************
   *  FOUNDER ROUTES
   */
  founderRoot: () => V2Routes.FOUNDER_ROOT,
  founderHomepage: () => V2Routes.FOUNDER_HOMEPAGE,
  founderProfile: () => V2Routes.FOUNDER_PROFILE,
  founderNotifications: () => V2Routes.FOUNDER_NOTIFICATIONS,
  founderMessages: () => V2Routes.FOUNDER_MESSAGES,
  founderInvestors: () => V2Routes.FOUNDER_INVESTORS,
  founderPublicProfileInvestor: (params) => assignParams(V2Routes.FOUNDER_PUBLIC_PROFILE_INVESTOR, params),
  founderAdvisors: () => V2Routes.FOUNDER_ADVISORS,
  founderAdvisorsSearchRole: (params) => assignParams(V2Routes.FOUNDER_ADVISORS_SEARCH_ROLE, params),
  founderPublicProfileAdvisor: (params) => assignParams(V2Routes.FOUNDER_PUBLIC_PROFILE_ADVISOR, params),
  founderReport: (params) => assignParams(V2Routes.FOUNDER_REPORT, params),
  founderMetrics: (params) => assignParams(V2Routes.FOUNDER_METRICS, params),
  founderRoles: (params) => assignParams(V2Routes.FOUNDER_ROLES, params),
  founderRolesCreate: () => V2Routes.FOUNDER_ROLES_CREATE,
  founderRolesPreview: (params) => assignParams(V2Routes.FOUNDER_ROLES_PREVIEW, params),
  founderRolesEdit: (params) => assignParams(V2Routes.FOUNDER_ROLES_EDIT, params),
  founderRolesApplications: (params) => assignParams(V2Routes.FOUNDER_ROLES_APPLICATIONS, params),
  founderLegal: () => V2Routes.FOUNDER_LEGAL,
  founderConnections: () => V2Routes.FOUNDER_CONNECTIONS,
  founderConfirmInvestment: (params) => assignParams(V2Routes.FOUNDER_CONFIRM_INVESTMENT, params),
  /**********************************************************
   *  INVESTORS ROUTES
   */
  investorRoot: () => V2Routes.INVESTOR_ROOT,
  investorHomepage: () => V2Routes.INVESTOR_HOMEPAGE,
  investorOnboarding: () => V2Routes.ONBOARDING_INVESTOR,
  investorProfile: () => V2Routes.INVESTOR_PROFILE,
  investorMessages: () => V2Routes.INVESTOR_MESSAGES,
  investorNotifications: () => V2Routes.INVESTOR_NOTIFICATIONS,
  investorLens: (params) => assignParams(V2Routes.INVESTOR_LENS, params),
  investorPulse: (params) => assignParams(V2Routes.INVESTOR_PULSE, params),
  investorPublicProfileFounder: (params) => assignParams(V2Routes.INVESTOR_PUBLIC_PROFILE_FOUNDER, params),
  investorPortfolio: () => V2Routes.INVESTOR_PORTFOLIO,
  investorPortfolioExpandedView: () => V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW,
  investorPortfolioExpandedViewSingle: (params) =>
    assignParams(V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW_SINGLE, params),
  investorPortfolioCompanyDetails: (params) => assignParams(V2Routes.INVESTOR_PORTFOLIO_COMPANY_DETAILS, params),
  investorConfirmInvestment: (params) => assignParams(V2Routes.INVESTOR_CONFIRM_INVESTMENT, params),
  /**********************************************************
   *  ADVISOR ROUTES
   */
  advisorRoot: () => V2Routes.ADVISOR_ROOT,
  advisorHomepage: () => V2Routes.ADVISOR_HOMEPAGE,
  advisorProfile: () => V2Routes.ADVISOR_PROFILE,
  advisorNotifications: () => V2Routes.ADVISOR_NOTIFICATIONS,
  advisorMessages: () => V2Routes.ADVISOR_MESSAGES,
  advisorAcademy: () => V2Routes.ADVISOR_ACADEMY,
  advisorOpportunities: (params) => assignParams(V2Routes.ADVISOR_OPPORTUNITIES, params),
  advisorOpportunitiesRolePreview: (params) => assignParams(V2Routes.ADVISOR_OPPORTUNITIES_ROLE_PREVIEW, params),
  advisorOpportunitiesPlacements: () => V2Routes.ADVISOR_OPPORTUNITIES_PLACEMENTS,
  advisorLens: () => V2Routes.ADVISOR_LENS,
  advisorLegal: () => V2Routes.ADVISOR_LEGAL,
  advisorPublicProfileFounder: (params) => assignParams(V2Routes.ADVISOR_PUBLIC_PROFILE_FOUNDER, params),
  advisorConnections: () => V2Routes.ADVISOR_CONNECTIONS,

  /**********************************************************
   *  INVESTOR-ADVISOR ROUTES
   */
  investorAdvisor: () => V2Routes.INVESTOR_ADVISOR_ROOT,
  investorAdvisorHomepage: () => V2Routes.INVESTOR_ADVISOR_HOMEPAGE,
  investorAdvisorMessages: () => V2Routes.INVESTOR_ADVISOR_MESSAGES,
  investorAdvisorNotifications: () => V2Routes.INVESTOR_ADVISOR_NOTIFICATIONS,
  investorAdvisorLegal: () => V2Routes.INVESTOR_ADVISOR_LEGAL,
  investorAdvisorOpportunities: (params) => assignParams(V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES, params),
  investorAdvisorOpportunitiesRolePreview: (params) =>
    assignParams(V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES_ROLE_PREVIEW, params),
  investorAdvisorOpportunitiesPlacements: () => V2Routes.INVESTOR_ADVISOR_OPPORTUNITIES_PLACEMENTS,
  investorAdvisorProfile: (params) => assignParams(V2Routes.INVESTOR_ADVISOR_PROFILE, params),
  investorAdvisorPortfolio: () => V2Routes.INVESTOR_ADVISOR_PORTFOLIO,
  investorAdvisorPortfolioExpandedView: () => V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW,
  investorAdvisorPortfolioExpandedViewSingle: (params) =>
    assignParams(V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW_SINGLE, params),
  investorAdvisorPortfolioCompanyDetails: (params) =>
    assignParams(V2Routes.INVESTOR_ADVISOR_PORTFOLIO_COMPANY_DETAILS, params),
  investorAdvisorConfirmInvestment: (params) => assignParams(V2Routes.INVESTOR_ADVISOR_CONFIRM_INVESTMENT, params),
  investorAdvisorLens: (params) => assignParams(V2Routes.INVESTOR_ADVISOR_LENS, params),
  investorAdvisorPulse: (params) => assignParams(V2Routes.INVESTOR_ADVISOR_PULSE, params),
  investorAdvisorPublicProfileFounder: (params) =>
    assignParams(V2Routes.INVESTOR_ADVISOR_PUBLIC_PROFILE_FOUNDER, params),
  investorAdvisorConnections: () => V2Routes.INVESTOR_ADVISOR_CONNECTIONS,
  investorAdvisorAcademy: () => V2Routes.INVESTOR_ADVISOR_ACADEMY,

  /**********************************************************
   *  ERROR ROUTES
   */
  error404: () => V2Routes.ERROR_404,
  error500: () => V2Routes.ERROR_500,
};
