import { Roles } from '@/domain/accounts/roles';
import type { RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { withGuard } from '../utils/with-guard/with-guard';
import { withLazy } from '../utils/with-lazy';

/**********************************************************
 *  LAZY LOADING COMPONENTS
 */
const { AdvisorLayout } = withLazy(() => import('../layouts/advisor-layout'), 'AdvisorLayout');

const { Homepage } = withLazy(() => import('@/modules/advisor/homepage/homepage'), 'Homepage');

const { NedProfilePage } = withLazy(() => import('@/pages/content/profile/ned/ned-profile.page'), 'NedProfilePage');

const { Chat: NedMessaging } = withLazy(() => import('@/pages/content/messaging/parts/chat/chat'), 'Chat');

const { NedNotifications } = withLazy(
  () => import('@/pages/content/notifications/ned/ned-notifications.page'),
  'NedNotifications',
);

const { NedAcademyAccessPage } = withLazy(
  () => import('@/pages/utility/ned-academy-access/ned-academy-access.page'),
  'NedAcademyAccessPage',
);

const { NedCatalogue: NedRoleCatalogue } = withLazy(
  () => import('@/pages/content/roles/catalogue/ned/ned.page'),
  'NedCatalogue',
);

const { NedPreview: NedRolePreview } = withLazy(
  () => import('@/pages/content/roles/preview/ned/ned.page'),
  'NedPreview',
);

const { FounderPublicPage } = withLazy(
  () => import('@/pages/content/public-profile/founder/founder.page'),
  'FounderPublicPage',
);

const { AdvisorConnectionsPage } = withLazy(
  () => import('@/modules/advisor/connections/connections-page'),
  'AdvisorConnectionsPage',
);

const { Legal } = withLazy(() => import('@/pages/content/legal/legal.page'), 'Legal');

const { Startups } = withLazy(() => import('@/modules/advisor/startups/startups'), 'Startups');

/**********************************************************
 * GUARDED COMPONENTS
 */
const GuardedAdvisorLayout = withGuard(AdvisorLayout, {
  roles: [Roles.NED],
  isActive: true,
  hasCor: true,
});

export const advisorRoutes: RouteObject[] = [
  {
    path: V2Routes.ADVISOR_ROOT,
    element: <GuardedAdvisorLayout />,
    children: [
      { path: V2Routes.ADVISOR_HOMEPAGE, element: <Homepage /> },
      { path: V2Routes.ADVISOR_PROFILE, element: <NedProfilePage /> },
      { path: V2Routes.ADVISOR_MESSAGES, element: <NedMessaging /> },
      { path: V2Routes.ADVISOR_NOTIFICATIONS, element: <NedNotifications /> },
      { path: V2Routes.ADVISOR_ACADEMY, element: <NedAcademyAccessPage /> },
      { path: V2Routes.ADVISOR_OPPORTUNITIES, element: <NedRoleCatalogue /> },
      { path: V2Routes.ADVISOR_OPPORTUNITIES_ROLE_PREVIEW, element: <NedRolePreview /> },
      { path: V2Routes.ADVISOR_LENS, element: <Startups /> },
      { path: V2Routes.ADVISOR_LEGAL, element: <Legal /> },
      { path: V2Routes.ADVISOR_PUBLIC_PROFILE_FOUNDER, element: <FounderPublicPage /> },
      { path: V2Routes.ADVISOR_CONNECTIONS, element: <AdvisorConnectionsPage /> },
    ],
  },
];
