import { Suspense, lazy } from 'react';
import { LoaderEmptyNotificationsStateProvider } from './LoaderEmptyNotificationsStateProvider';

const NotificationsStateProvider = lazy(() =>
  import('./notifications.provider').then((m) => ({ default: m.NotificationsStateProvider })),
);

export const LazyNotificationsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense fallback={<LoaderEmptyNotificationsStateProvider>{children}</LoaderEmptyNotificationsStateProvider>}>
      <NotificationsStateProvider>{children}</NotificationsStateProvider>
    </Suspense>
  );
};
