import { Roles } from '@/domain/accounts/roles';
import type { RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { withGuard } from '../utils/with-guard/with-guard';
import { withLazy } from '../utils/with-lazy';

/**********************************************************
 *  LAZY LOADING COMPONENTS
 */
const { FounderLayout } = withLazy(() => import('../layouts/founder-layout'), 'FounderLayout');

const { Homepage: FounderHomepage } = withLazy(() => import('@/modules/founder/homepage/homepage'), 'Homepage');

const { FounderProfilePage } = withLazy(
  () => import('@/pages/content/profile/founder/founder-profile.page'),
  'FounderProfilePage',
);

const { FounderInvestorsPage } = withLazy(
  () => import('@/pages/content/lens/founder/founder-investors.page'),
  'FounderInvestorsPage',
);

const { AdvisorsSearchPage } = withLazy(
  () => import('@/pages/content/advisors-search/advisors-search.page'),
  'AdvisorsSearchPage',
);

const { FounderConnectionsPage } = withLazy(
  () => import('@modules/founder/connections/connections-page'),
  'FounderConnectionsPage',
);

const { AdvisorsPage } = withLazy(() => import('@/pages/content/advisors/advisors.page'), 'AdvisorsPage');

const { CreateRolePage } = withLazy(
  () => import('@/pages/content/roles/management/create-role/create-role.page'),
  'CreateRolePage',
);

const { EditRolePage } = withLazy(
  () => import('@/pages/content/roles/management/edit-role/edit-role.page'),
  'EditRolePage',
);

const { InvestorPreviewPage } = withLazy(
  () => import('@pages/content/public-profile/investor/investor.page'),
  'InvestorPreviewPage',
);

const { ListApplicationsPage } = withLazy(
  () => import('@pages/content/roles/parts/applications/applications/applications.page'),
  'ListApplicationsPage',
);

const { ManagementPage } = withLazy(
  () => import('@pages/content/pulse/founder/parts/management/management'),
  'ManagementPage',
);

const { NedPublicPage } = withLazy(() => import('@pages/content/public-profile/ned/ned.page'), 'NedPublicPage');

const { Chat: FounderMessaging } = withLazy(() => import('@/pages/content/messaging/parts/chat/chat'), 'Chat');

const { FounderNotifications } = withLazy(
  () => import('@/pages/content/notifications/founder/founder-notifications.page'),
  'FounderNotifications',
);

const { FounderPulsePage } = withLazy(
  () => import('@/pages/content/pulse/founder/founder-pulse.page'),
  'FounderPulsePage',
);

const { FounderCatalogue } = withLazy(
  () => import('@/pages/content/roles/catalogue/founder/founder.page'),
  'FounderCatalogue',
);

const { FounderPreview } = withLazy(
  () => import('@/pages/content/roles/preview/founder/founder.page'),
  'FounderPreview',
);

const { Legal } = withLazy(() => import('@/pages/content/legal/legal.page'), 'Legal');

const { InvestmentConfirmationPage } = withLazy(
  () => import('@/pages/content/investment-confirmation/investment-confirmation.page'),
  'InvestmentConfirmationPage',
);

/**********************************************************
 *  GUARDED COMPONENTS
 */
const GuardedFounderLayout = withGuard(FounderLayout, {
  roles: [Roles.FOUNDER],
  isActive: true,
  hasCor: true,
});

export const founderRoutes: RouteObject[] = [
  {
    path: V2Routes.FOUNDER_ROOT,
    element: <GuardedFounderLayout />,
    children: [
      { path: V2Routes.FOUNDER_HOMEPAGE, element: <FounderHomepage /> },
      { path: V2Routes.FOUNDER_PROFILE, element: <FounderProfilePage /> },
      { path: V2Routes.FOUNDER_INVESTORS, element: <FounderInvestorsPage /> },
      { path: V2Routes.FOUNDER_ADVISORS, element: <AdvisorsPage /> },
      { path: V2Routes.FOUNDER_ADVISORS_SEARCH_ROLE, element: <AdvisorsSearchPage /> },
      { path: V2Routes.FOUNDER_REPORT, element: <FounderPulsePage /> },
      { path: V2Routes.FOUNDER_METRICS, element: <ManagementPage /> },
      { path: V2Routes.FOUNDER_LEGAL, element: <Legal availableBlocking /> },
      { path: V2Routes.FOUNDER_NOTIFICATIONS, element: <FounderNotifications /> },
      { path: V2Routes.FOUNDER_MESSAGES, element: <FounderMessaging /> },
      { path: V2Routes.FOUNDER_PUBLIC_PROFILE_INVESTOR, element: <InvestorPreviewPage /> },
      { path: V2Routes.FOUNDER_PUBLIC_PROFILE_ADVISOR, element: <NedPublicPage /> },
      { path: V2Routes.FOUNDER_ROLES, element: <FounderCatalogue /> },
      { path: V2Routes.FOUNDER_ROLES_CREATE, element: <CreateRolePage /> },
      { path: V2Routes.FOUNDER_ROLES_PREVIEW, element: <FounderPreview /> },
      { path: V2Routes.FOUNDER_ROLES_EDIT, element: <EditRolePage /> },
      { path: V2Routes.FOUNDER_ROLES_APPLICATIONS, element: <ListApplicationsPage /> },
      { path: V2Routes.FOUNDER_CONNECTIONS, element: <FounderConnectionsPage /> },
      { path: V2Routes.FOUNDER_CONFIRM_INVESTMENT, element: <InvestmentConfirmationPage /> },
    ],
  },
];
