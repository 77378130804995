import { AppProvider } from '@context/app/app.provider';
import { passiveSupport } from 'passive-events-support/src/utils';
import { render } from 'react-dom';
import { App } from './app';
import reportWebVitals from './reportWebVitals';

const renderApp = async () => {
  passiveSupport({ events: ['wheel'] });
  render(
    <AppProvider>
      <App />
    </AppProvider>,
    document.getElementById('root'),
  );
  reportWebVitals();
};

renderApp();
