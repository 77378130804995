import type { FunctionComponent } from 'react';
import { NotificationsContext, notificationsInitialState } from './notifications.context';

export const LoaderEmptyNotificationsStateProvider: FunctionComponent = ({ children }) => {
  return (
    <NotificationsContext.Provider value={{ state: notificationsInitialState, dispatch: () => {} }}>
      {children}
    </NotificationsContext.Provider>
  );
};
