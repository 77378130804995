import type { Any } from '@/types';
import { AppMessages } from '@i18n/messages';
import { FormattedMessage, type MessageDescriptor } from 'react-intl';

type Props = Omit<MessageDescriptor, 'id'> & {
  tagName?: React.ElementType<Any>;
  id: keyof typeof AppMessages;
  values?: Record<string, string | ((text: string) => JSX.Element)>;
};

export const TranslationText = (props: Props) => <FormattedMessage {...props} />;
