import { AccountStatus } from '@context/user/user-account.context';
import { type SubscriptionProduct } from '@domain/accounts/subscription-products';
import { OnboardingStatus } from '@domain/accounts/types';
import type { CurrencyValue } from '@utils/type-definitions/currencies';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';

export enum PermissionNames {
  LegalDocs = 'legalDocs',
  Portfolio = 'PMT',
  AdvisorCommunity = 'AdvisorCommunity',
  NedAcademy = 'NedAcademy',
  NotApplicable = 'NotApplicable',
}

export enum Auth0ProviderValue {
  Email = 'EmailPassword',
  Google = 'GoogleOauth2',
  Linkedin = 'LinkedinOauth2',
}

export interface ApiDefinedPermission {
  name: PermissionNames;
  allowed: boolean;
}

export interface RoleOnboardingStatus {
  founder: OnboardingStatus;
  investor: OnboardingStatus;
  ned: OnboardingStatus;
  founderQualifyingQuestions: OnboardingStatus;
}

export interface AccountDataResponse {
  id: string;
  email: string;
  role: string | null;
  integrations: {
    codat: {
      isConnected: boolean;
      isFirstSyncCompleted: boolean;
    };
    crm: {
      id: string | null;
    };
    stripe: {
      customerId: string | null;
    };
  };
  products: SubscriptionProduct[];
  permissions: ApiDefinedPermission[];
  avatarUrl: string | null;
  name: string;
  businessName: string | null;
  currency: CurrencyValue | null;
  countryOfResidence: CountryCodes | null;
  status: AccountStatus;
  auth0Provider: Auth0ProviderValue | null;
  onboardingStatus: RoleOnboardingStatus;
}
