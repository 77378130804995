import { Suspense, lazy, type FC } from 'react';
import { MessagingContextProvider } from './messaging.context';

const LazyTalkJsSessionProvider = lazy(() =>
  import('./talkjs-session-provider').then(async (module) => ({ default: module.TalkJsSessionProvider })),
);

export const MessagingProvider: FC = ({ children }) => {
  return (
    <MessagingContextProvider>
      <Suspense fallback={<>{children}</>}>
        <LazyTalkJsSessionProvider>{children}</LazyTalkJsSessionProvider>
      </Suspense>
    </MessagingContextProvider>
  );
};
