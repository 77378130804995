import { V2Icon } from '@/components/ui/v2-icon/v2-icon';

import S from './v2-modal-navigation.styles';

export const V2ModalNavigation = ({
  children,
  disabledPrev,
  disabledNext,
  onPrev,
  onNext,
}: {
  children: React.ReactNode;
  disabledPrev: boolean;
  disabledNext: boolean;
  onPrev: () => void;
  onNext: () => void;
}) => {
  return (
    <S.ContentWrapper>
      {children}

      <S.ButtonPrev
        disabled={disabledPrev}
        onClick={onPrev}
        data-testid="v2-modal-navigation-button-prev"
        aria-label="previous"
      >
        <V2Icon name="chevron-left" />
      </S.ButtonPrev>
      <S.ButtonNext
        disabled={disabledNext}
        onClick={onNext}
        data-testid="v2-modal-navigation-button-next"
        aria-label="next"
      >
        <V2Icon name="chevron-right" />
      </S.ButtonNext>
    </S.ContentWrapper>
  );
};
